import { gql } from 'graphql-request'
import { GenericBlock } from '../generic'
import { ExcludedPlatforms } from 'app/types/excludedPlatforms'

export const wrappedFragment = gql`
  fragment wrappedFragment on Wrapped {
    id
    wrappedTitle: title
    ctaLabel
    wrappedDescription: description
    scheduling {
      startDate
      endDate
    }
    excludedPlatforms {
      ios
      android
      web
    }
  }
`

export interface Wrapped extends GenericBlock<'Wrapped'> {
  wrappedTitle: string
  ctaLabel?: string
  wrappedDescription?: string
  excludedPlatforms: ExcludedPlatforms
  scheduling: {
    startDate: string
    endDate: string
  }
}
